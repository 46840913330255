<script>
  import { onMount } from "svelte";

  export let job_spec;
  export let asset_dir = "/assets";

  let canvas;
  let ctx;
  let size = 432;

  $: height = size;
  $: width = size;

  let customFonts = [
    {
      name: "Raleway",
      src: `${asset_dir}/fonts/raleway-v29-latin-regular.woff2`,
      weight: 400,
    },
    {
      name: "RalewayBold",
      src: `${asset_dir}/fonts/raleway-v29-latin-700.woff2`,
      weight: 700,
    },
  ];

  let dims = {
    post: {
      padding: {
        y: 16,
        x: 28,
      },
    },
    top: {
      margin: {
        y: 32,
      },
    },
    heading: {
      w: 260,
      h: 50,
    },
    icon: {
      size: 35,
      margin: 10,
    },
    footer: {
      w: 100,
      h: 20,
    },
    jobRole: {
      fontSize: 22,
    },
    lineHeight: 1.3,
    textSpacing: 1.28,
  };

  let images = {
    background: {
      path: `${asset_dir}/background.jpg`,
      x: -1,
      y: -1,
      w: size + 2,
      h: size + 2,
    },
    footer: {
      path: `${asset_dir}/logo.png`,
      x: size - dims.footer.w - dims.post.padding.y,
      y: size - dims.post.padding.y - dims.footer.h,
      w: dims.footer.w,
      h: dims.footer.h,
    },
    header: {
      path: `${asset_dir}/jobalert.png`,
      x: dims.post.padding.x,
      y: dims.post.padding.y + dims.top.margin.y,
      w: dims.heading.w,
      h: dims.heading.h,
    },
    accredited: {
      path: `${asset_dir}/icon_acc.png`,
      x: dims.post.padding.x + dims.heading.w + dims.icon.margin,
      y:
        dims.post.padding.y +
        dims.top.margin.y +
        (dims.heading.h - dims.icon.size) / 2,
      w: dims.icon.size,
      h: dims.icon.size,
    },
    entrant: {
      path: `${asset_dir}/icon_new.png`,
      x:
        dims.post.padding.x +
        dims.heading.w +
        dims.icon.margin * 2 +
        dims.icon.size,
      y:
        dims.post.padding.y +
        dims.top.margin.y +
        (dims.heading.h - dims.icon.size) / 2,
      w: dims.icon.size,
      h: dims.icon.size,
    },
  };

  // let mounted = false;

  // $: {
  //   if (mounted) {
  //     renderCanvas(job_spec);
  //   }
  // }

  onMount(() => {
    canvas = document.getElementById("canvas");
    ctx = canvas.getContext("2d");
    // mounted = true;
    renderCanvas();
  });

  function renderCanvas() {
    console.log("rendering canvas");

    window.devicePixelRatio = 2.5;

    canvas.style.width = width + "px";
    canvas.style.height = height + "px";

    var scale = window.devicePixelRatio;
    canvas.width = Math.floor(size * scale);
    canvas.height = Math.floor(size * scale);
    ctx.scale(scale, scale);
    ctx.fillStyle = "black";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    loadFonts(customFonts)
      .then(() => loadImages())
      .then(() => {
        drawImage(images.background);
        drawImage(images.header);
        drawImage(images.footer);

        if (job_spec.icon_accredited && job_spec.icon_entrant) {
          // both
          drawImage(images.accredited);
          drawImage(images.entrant);
        } else if (job_spec.icon_accredited && !job_spec.icon_entrant) {
          // accredited only
          drawImage(images.accredited);
        } else if (!job_spec.icon_accredited && job_spec.icon_entrant) {
          // entrants only
          let updatedOptions = images.entrant;
          updatedOptions.x =
            dims.post.padding.x + dims.heading.w + dims.icon.margin;
          drawImage(updatedOptions);
        }
      })
      .then(() => {
        let jobsHeight = 0;
        let totalJobs = job_spec.roles.length;

        for (let [i, el] of job_spec.roles.entries()) {
          if (i !== totalJobs - 1) el += ",";

          if (i > 5) {
            drawJobs(
              "and more...",
              dims.post.padding.y +
                dims.top.margin.y * 2 +
                dims.heading.h +
                dims.jobRole.fontSize * dims.lineHeight * i
            );
            jobsHeight += dims.jobRole.fontSize * dims.lineHeight;
            break;
          }

          drawJobs(
            el,
            dims.post.padding.y +
              dims.top.margin.y * 2 +
              dims.heading.h +
              dims.jobRole.fontSize * dims.lineHeight * i
          );
          jobsHeight += dims.jobRole.fontSize * dims.lineHeight;
        }

        if (job_spec.flexible_considered) {
          drawFlexible(
            "Flexible working considered",
            dims.post.padding.y +
              dims.top.margin.y * 2 +
              dims.heading.h +
              jobsHeight +
              5
          );
        }

        drawText(job_spec.caption.line_1, height - 16 - 32 - 16);
        drawText(job_spec.caption.line_2, height - 16 - 32 + 6);

        drawNumber(job_spec.id);
      });
  }

  function loadFonts(fonts) {
    return Promise.all(
      fonts.map((font) => {
        const { name, src } = font;
        const fontFace = new FontFace(name, `url(${src})`);
        return fontFace.load();
      })
    ).then((loadedFonts) => {
      loadedFonts.forEach((font) => {
        document.fonts.add(font);
      });
    });
  }

  function loadImages() {
    return new Promise((resolve) => {
      let imageKeys = Object.entries(images);
      Promise.all(
        imageKeys.map(([name, options]) => loadImage(name, options))
      ).then(resolve);
    });
  }

  function loadImage(name, { path, x, y, w, h }) {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = path;
      if (img.complete) {
        images[name]._img = img;
        resolve();
      } else {
        img.onload = () => {
          images[name]._img = img;
          resolve();
        };
      }
    });
  }

  function drawImage({ _img, x, y, w, h }) {
    console.log(_img, x, y, w, h);

    if (ctx && _img) {
      console.log("drawing image");
      ctx.drawImage(_img, x, y, w, h);
    }
  }

  function drawNumber(number) {
    if (ctx) {
      let text = `No: ${number.toString().padStart(5, "0")}`;
      ctx.letterSpacing = "1.28px";
      ctx.fillStyle = "white";
      ctx.font = "12px Raleway";

      let textWidth = ctx.measureText(text).width;

      console.log(textWidth);
      ctx.fillText(
        text,
        width - textWidth - dims.post.padding.y,
        dims.post.padding.y + 12
      );
    }
  }

  function drawText(text, y) {
    if (ctx) {
      ctx.fillStyle = "white";
      ctx.font = "18px Raleway";
      ctx.fillText(text, 28, y);
    }
  }

  function drawFlexible(text, y) {
    if (ctx) {
      ctx.fillStyle = "rgba(255, 255, 255, 0.5)";
      ctx.letterSpacing = `${dims.textSpacing}px}`;
      ctx.shadowColor = "rgba(255, 255, 255, 0.6)";
      ctx.shadowBlur = 10;
      ctx.font = "18px Raleway";
      ctx.fillText(text, 28, y);

      ctx.shadowBlur = 0;
      ctx.shadowColor = "transparent";
      ctx.letterSpacing = "0px";
    }
  }

  function drawJobs(text, y) {
    if (ctx) {
      ctx.fillStyle = "white";
      ctx.letterSpacing = `${dims.textSpacing}px}`;
      ctx.shadowColor = "rgba(255, 255, 255, 0.8)";
      ctx.shadowBlur = 10;
      ctx.font = `22px RalewayBold`;
      ctx.fillText(text, 28, y);

      ctx.shadowBlur = 0;
      ctx.shadowColor = "transparent";
      ctx.letterSpacing = "0px";
    }
  }

  function saveImage() {
    const image = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    var element = document.createElement("a");
    var filename = `instagram_${job_spec.id.toString().padStart(5, "0")}.png`;
    element.setAttribute("href", image);
    element.setAttribute("download", filename);
    element.click();
  }
</script>

<div class="wrap">
  <div class="col">
    <canvas id="canvas" {width} {height}></canvas>
    <button
      id="downloadButton"
      class="mud-button-root mud-button mud-button-filled mud-button-filled-primary mud-button-filled-size-medium mud-ripple"
      on:click={saveImage}
    >
      <span class="mud-button-label">Save as Image</span>
    </button>
    <details>
      <summary class="mud-typography mud-typography-body1"
        ><strong>Debug Input</strong></summary
      >
      <pre>{JSON.stringify(job_spec, null, 4)}</pre>
    </details>
  </div>
</div>

<style>
  details {
    background-color: lightgrey;
    border-radius: 4px;
    padding: 1rem;
    margin: 0;
    max-width: 432px;
    box-sizing: border-box;
  }

  pre {
    overflow-x: scroll;
  }

  summary {
    margin-bottom: 0.5rem;
  }

  /* pre + button {
    margin-top: 2rem;
  } */

  .col {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .col button {
    max-width: 432px;
  }
</style>
